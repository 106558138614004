<template>
  <form @submit.prevent="onSubmit">
    <div v-show="response.message" class="w-100 text-sm text-center">
      <span :class="response.error ? 'text-danger' : 'text-success'">{{ response.message }}</span>
    </div>

    <div class="cell--center">
      <b-input-group :size="size" title="title">
        <template #prepend>
          <b-button v-if="isDirty && !isLoading" variant="dark" @click="onCancel">
            Cancelar
          </b-button>
          <b-input-group-text><strong class="text-black">{{ prependValue }}</strong></b-input-group-text>
        </template>

        <input
          v-mask="mask"
          :type="type"
          v-model="inputModel"
          @input="onInput"
          placeholder="Ingresa el importe"
          class="form-control"
          :disabled="isLoading"
        />

        <template #append v-if="isDirty">
          <b-button variant="primary" type="submit" :disabled="isLoading">
            {{ isLoading ? 'Procesando...' : 'Aplicar' }}
          </b-button>
        </template>
      </b-input-group>
    </div>
  </form>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  // TODO: refactorizar para especificarlo como una propiedad del componente que por omisión sea igual a 2
  decimalLimit: 16,
  includeThousandsSeparator: true,
  allowNegative: false
})

export default {
  name: 'XInputGroupCurrency16',

  props: {
    item: {
      type: Object,
      required: true
    },

    value: {
      required: true
    },

    type: {
      type: String,
      required: false,
      default: 'text'
    },

    prependValue: {
      type: String,
      required: false,
      default: '$'
    },

    attachValueName: {
      type: String,
      required: false,
      default: 'value'
    },

    attachValueToPayload: {
      type: Boolean,
      required: false,
      default: false
    },

    dispatchPath: {
      type: String,
      required: false,
      default: null
    },

    payload: {
      type: Object,
      required: false,
      default: () => ({})
    },

    size: {
      type: String,
      required: false,
      default: 'sm'
    }
  },

  created () {
    this.l_value = this.value
  },

  data () {
    return {
      mask: currencyMask,

      l_value: null,

      inputModel: this.value,

      response: {
        error: false,
        message: ''
      },

      isLoading: false
    }
  },

  computed: {
    isDirty () {
      if (this.inputModel === '' && this.l_value === '') return false

      return this.$unformatNumber(this.inputModel) !== this.$unformatNumber(this.l_value)
    }
  },

  watch: {
    value (val) {
      const getval = {
        data: {
          [this.attachValueName]: val
        }
      }

      this.reAssignData(getval)
    }
  },

  methods: {
    onInput () {
      this.emitOnInput()
    },

    onCancel () {
      this.inputModel = this.l_value

      this.emitOnInput()
    },

    onShowResponse (getval) {
      this.response.error = getval.error
      this.response.message = getval.message

      this.$emit('on-show-response', { error: getval.error, message: getval.message })

      this.$delay(8000).then(() => {
        this.response.error = false
        this.response.message = ''
      })
    },

    emitOnInput () {
      const unformatted = this.$unformatNumber(this.inputModel)

      this.$emit('on-input', { item: this.item, value: unformatted })
    },

    onSubmit () {
      const unformatted = this.$unformatNumber(this.inputModel)

      this.$emit('on-submitted', { item: this.item, value: unformatted })

      this.dispatch(unformatted)
    },

    async dispatch (unformatted) {
      if (this.dispatchPath === null) return

      const payload = { ...this.payload }

      if (this.attachValueToPayload) {
        payload.payload[this.attachValueName] = unformatted
      }

      const params = { [this.attachValueName]: unformatted, ...payload }

      this.isLoading = true
      const getval = await this.$store.dispatch(this.dispatchPath, params)
      this.isLoading = false

      this.onShowResponse(getval)

      this.$emit('on-dispatched', getval)

      this.reAssignData(getval)
    },

    reAssignData (getval) {
      this.l_value = getval.data ? getval.data[this.attachValueName] : ''

      this.inputModel = this.l_value
    }
  }
}
</script>
