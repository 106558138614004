<template>
  <b-table
    :fields="fields"
    :items="items"
    :busy="loading"
    sticky-header="100%"
    head-variant="light"
    responsive
    bordered
    small
    :filter="filter"
    stacked="md"
  >
    <template #table-busy>
      <div class="text-center my-2" style="min-height: 25vh">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Cargando...</strong>
      </div>
    </template>

    <template #cell(capituloclave)="data">
      <div class="cell--center text-sm">{{ data.item.capitulo.clave }}</div>
    </template>

    <template #cell(capitulonombre)="data">
      <div class="cell--center-text text-sm">{{ data.item.capitulo.nombre }}</div>
    </template>

    <template #cell(techo_financiero)="row">
      <x-input-group-currency
        :item="row.item"
        :value="row.item.techo_financiero"
        type="text"
        size="sm"
        attach-value-name="techo_financiero"
        :payload="getPayloadForUpdate(row.item, 'techo_financiero')"
        dispatch-path="fuenteFinanciamientoCapituloModule/updateById"
        @on-show-response="
          $alert($event, 'Fuentes de financiamiento', 'notificationAlertXModalMessage')
        "
        @on-dispatched="getCapitulosFuenteFinanciamiento"
      />
    </template>

    <template #cell(porcentaje_incremento)="row">
      <x-input-group-currency16
        prepend-value="%"
        type="text"
        :item="row.item"
        :value="row.item.porcentaje_incremento"
        size="sm"
        attach-value-name="porcentaje_incremento"
        :payload="getPayloadForUpdate(row.item, 'porcentaje_incremento')"
        dispatch-path="fuenteFinanciamientoCapituloModule/updateById"
        @on-show-response="
          $alert($event, 'Fuentes de financiamiento', 'notificationAlertXModalMessage')
        "
        @on-dispatched="getCapitulosFuenteFinanciamiento"
      />
    </template>
  </b-table>
</template>

<script>
import XInputGroupCurrency from '@/components/Shared/XInputGroupCurrency'
import XInputGroupCurrency16 from '@/components/Shared/XInputGroupCurrency16'

export default {
  name: 'FuenteFinanciamientoCapitulos',

  components: {
    XInputGroupCurrency,
    XInputGroupCurrency16
  },

  props: {
    updateData: {
      type: Object,
      required: false,
      default: () => ({})
    },

    filter: {
      type: String,
      required: false,
      default: ''
    }
  },

  created () {
    this.getCapitulosFuenteFinanciamiento()
  },

  data () {
    return {
      fields: [
        { key: 'capituloclave', label: 'Capítulo' },
        { key: 'capitulonombre', label: 'Nombre' },
        { key: 'techo_financiero', label: 'Techo Financiero' },
        { key: 'porcentaje_incremento', label: 'Incremento' }
      ],

      items: [],

      loading: false
    }
  },

  methods: {
    async getCapitulosFuenteFinanciamiento () {
      this.loading = true
      const getval = await this.$store.dispatch(
        'fuenteFinanciamientoCapituloModule/getAll',
        this.updateData
      )
      this.loading = false

      if (getval.error) return

      this.items = getval.data
    },

    getPayloadForUpdate (item, deleteProperty) {
      //
      // Inicialmente se agregan todos los datos de las columnas
      // de la tabla.
      const payload = {
        id: item.id,
        fuente_financiamiento_id: item.fuente_financiamiento_id,
        techo_financiero: item.techo_financiero,
        porcentaje_incremento: item.porcentaje_incremento
      }

      //
      // Pero para hacer la petición, se debe de eliminar de éste
      // objeto la propiedad que se va a actualizar, la razón es que
      // el componente 'InputGroupCurrency' actualiza su valor, internamente.
      delete payload[deleteProperty]

      return payload
    }
  }
}
</script>
